/**
 * External dependencies
 */
 import React from "react";
 import Reviews from "../reviews/reviews";
 import Button from "../button/button";
 import parse from 'html-react-parser';
 import GETCTA from '../getcta';
 import ImageTransform from "../common/ggfx-client/module/components/image-transform";
 import { AltSiteName } from "../utils";
 import { isMobile } from 'react-device-detect';
 import Breadcrumbs from '../breadcrumbs'

 /**
  * Internal dependencies
  */
 import "./styles/_index.scss";
 
 const HomeIntro = (props) => {
   let processedImages = JSON.stringify({});
     if (props?.imagetransforms?.Staff_Image_Transforms) {
     processedImages = props.imagetransforms.Staff_Image_Transforms;
   }
   return (
     <div className={"home-intro team-intro"} data-aos="fade-up">
         
       
       <div className="shell">
         <Breadcrumbs baselabel="Our Team" baseurl="/about/our-team/" menulabel={props.title}/>
         <h1>
           {/* You local Estate Agent in <span>Sittingbourne, Isle of Sheppey</span>{" "}
           and surrounding areas. */}
           {parse(props.title)}
         </h1>
         {props.content &&
         <div className="banner-desc">{parse(props.content)}</div>
         }
           <div className="home-intro__actions">
            <Button className="btn btn--outline-white" link={`/contact?tid=${props.id}`}>Contact {props.title.split(' ')[0]}</Button>
             <Button link={`/contact?tid=${props.id}`} className="btn btn--outline-white" hideArrow="true" mailIcon = "true">Email me</Button>
             <a href={`tel:${props.phone}`}className="btn btn--outline-white" hideArrow="true" callIcon = "true">{props.phone}<i className="icon-ph-call"></i></a>
             <div className="team-intro">
              <ImageTransform imagesources={props.image && props.image.url} renderer="srcSet" imagename="teams.Staff_Image.detailtile" attr={{ alt:props.image.alternativeText?props.image.alternativeText:props.title+AltSiteName}} imagetransformresult={processedImages} id={props.id}/>  
            </div>            
           </div>
           {/* <div className="social_actions">
             <div className="linkedin"><a href={props.linkedin?props.linkedin:"javascript:;"} target="_blank"><i className="icon-link-share"></i>LinkedIn</a></div>
             <div className=""><a href={props.twitter?props.twitter:"javascript:;"} target="_blank"><i className="icon-t-share"></i>Twitter</a></div>
           </div> */}
            
       </div>
       
     </div>
   )
 }
 
 export default HomeIntro;
 