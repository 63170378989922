/**
 * External dependencies
 */
 import React, { useState } from "react"
 import { Link } from "gatsby"
 import Carousel, { consts } from "react-elastic-carousel"
 import { StaticImage } from "gatsby-plugin-image"
 import { useStaticQuery, graphql } from "gatsby"
 
 /**
  * Internal dependencies
  */
 import News from "../news/news"
 import "./styles/_index.scss"
 import classNames from "classnames"
 import VideoPlaceholder from "../video-placeholder/video-placeholder"
 import { OtherStaffs } from "../../queries/common_use_query";
 import DateFormat from "../format-date";
 import { AltSiteName, TeamURL } from "../utils"
 import ImageTransform from "../common/ggfx-client/module/components/image-transform";
 
 
 const HomeNews = (props) => {
   const {loading:loading, error:error, data:data} = OtherStaffs(props.url);
   const [isOpen, setIsOpen] = useState(false)
   const breakPoints = [
     { width: 1, itemsToShow: 1 },
     { width: 550, itemsToShow: 2 },
     { width: 800, itemsToShow: 3 },
     { width: 1128, itemsToShow: 4 },
   ]
 
   const myArrow = ({ type, onClick, isEdge }) => {
     const pointer =
       type === consts.PREV ? (
         <i className="icon-arrow-left"></i>
       ) : (
         <i className="icon-arrow-right"></i>
       )
     return (
       <span
         onClick={onClick}
         disabled={isEdge}
         className={classNames("slide-arrow", `slide-arrow--${type}`)}
       >
         {pointer}
       </span>
     )
   }
 
   return (
     <div className="home-news">
       <div className="shell">
         <div className="home-news__head">
           <h2>Other People</h2>
           <Link to="/about/our-team">View More</Link>
         </div>
 
         <div className="home-news__carousel">
           <Carousel breakPoints={breakPoints} renderArrow={myArrow}>
           {data && data.teams.length > 0 && data.teams.map((item, index) => {
             let processedImages = JSON.stringify({});
             if (item?.imagetransforms?.Staff_Image_Transforms) {
             processedImages = item.imagetransforms.Staff_Image_Transforms;
             }
             return<>
             <div className="news">
               <div className="news__head">
                 <div className="news__head-inner">
                   {item.Tile_Video_URL?
                     
                     <VideoPlaceholder
                     isOpen={isOpen}
                     setIsOpen={setIsOpen}
                     imagetype="GGFX"
                     ggfxname="teams.Staff_Image.listtile"
                     imageprocess={processedImages}
                     linkto={`${TeamURL}/${item.URL}`}
                     videourl={item.Tile_Video_URL}
                     imageid={item.id}
                     altid = {item.Staff_Image.alternativeText?item.Staff_Image.alternativeText:item.Title+AltSiteName}
                     image={
                       item.Staff_Image && item.Staff_Image.url
                     } />      
                     
                     :
                     <div className="overflow-image-wrapper">
                     <Link to={`${TeamURL}/${item.URL}`}>
                     <ImageTransform imagesources={item.Staff_Image.url} renderer="srcSet" imagename="teams.Staff_Image.listtile" attr={{ alt: item.Staff_Image.alternativeText?item.Staff_Image.alternativeText:item.Title+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>  
                     </Link>
                     </div>
                     // <img src={item.Staff_Image.url} />           
                   }
                 </div>
               </div>
               <div className="news__body">
                 <span className="news__heading"><Link to={`${TeamURL}/${item.URL}`}>{item.Name}</Link></span>
                 <p>{item.Designation}</p>
                 <p><a href={`tel:${item.Phone}`}>{item.Phone}</a> / <Link to={`/contact?tid=${item.id}`}>Email</Link></p>
               </div>
             </div>
             </>
           })}
           </Carousel>
         </div>
       </div>
     </div>
   )
 }
 
 export default HomeNews
 