/**
 * External dependencies
 */
import React, { useRef, useState, useEffect } from "react"
import Carousel from "react-elastic-carousel"
import axios from "axios"

/**
 * Internal dependencies
 */
import { isMobile, isTablet } from "../../lib/media"
import PropertyBox from "../property-box/property-box"

import "./styles/_index.scss"

const PropertyRelated = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLet, setIsLet] = useState(false)
  const [isSale, setIsSale] = useState(true)

  const carousel = useRef()
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 689, itemsToShow: 2 },
    { width: 900, itemsToShow: 3 },
    { width: 1100, itemsToShow: 4 },
  ]

  const title = isMobile
    ? "Feattured Properties"
    : props.title
    ? props.title
    : "Feattured Properties"

  //
  const [propItems, setPropItems] = useState([])
  const [propletItems, setletPropItems] = useState([])

  const showCarouselArrows = (isMobile || !isTablet)
  console.log(
    "🚀 ~ file: featured-property.js ~ line 40 ~ showCarouselArrows",
    showCarouselArrows
  )

  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        }})
      //
      // console.log("PropertySimilar", data)
      setPropItems(data)
    } catch (error) {
      // console.error(error)
    }
  }

  const getletitems = async leturl => {
    try {
      const { data } = await axios.get(leturl, {})
      //
      // console.log("PropertySimilar", data)
      setletPropItems(data)
    } catch (error) {
      // console.error(error)
    }
  }

  useEffect(() => {
    //
    let url =
      process.env.GATSBY_STRAPI_SRC +
      "/properties?search_type=sales&_limit=4&_sort=price:DESC&publish=true" // TODO: base URL supposed to be from .env
    let leturl =
      process.env.GATSBY_STRAPI_SRC +
      "/properties?search_type=lettings&_limit=4&_sort=price:DESC&publish=true"
    getitems(url)
    getletitems(leturl)
  }, [])
  //

  return (
    propItems.length > 0 && (
      <div className="property-related-wrapper">
        <div className="property-related" data-aos="fade-up">
          <div className="shell">
            <div className="property-related__inner">
              <div className="property-related__top">
                <h3>{title}</h3>

                {props.fromAreaguides && !isMobile && (
                  <div className="property-related__areaguide">
                    <p>
                      <a
                        className={isSale ? "activeprop" : ""}
                        href="javascript:;"
                        onClick={() => {
                          setIsSale(true)
                          setIsLet(false)
                        }}
                      >
                        Property for Sale in {props.areaName}
                      </a>
                    </p>
                    <p>
                      <a
                        className={isLet ? "activeprop" : ""}
                        href="javascript:;"
                        onClick={() => {
                          setIsLet(true)
                          setIsSale(false)
                        }}
                      >
                        Property to Rent in {props.areaName}
                      </a>
                    </p>
                  </div>
                )}

                {showCarouselArrows && (
                  <div className="property-related__arrows">
                    <button onClick={() => carousel.current.slidePrev()}>
                      <i className="icon-arrow-left" />
                    </button>
                    <button onClick={() => carousel.current.slideNext()}>
                      <i className="icon-arrow-right" />
                    </button>
                  </div>
                )}
              </div>
              {isSale && !isLet && (
                <Carousel
                  breakPoints={breakPoints}
                  showArrows={false}
                  ref={carousel}
                  showEmptySlots
                >
                  {propItems.map((item, index) => {
                    return (
                      <PropertyBox
                        data={item}
                        key={""}
                        setIsOpen={setIsOpen}
                        isOpen={isOpen}
                      />
                    )
                  })}
                </Carousel>
              )}
              {isLet && !isSale && (
                <Carousel
                  breakPoints={breakPoints}
                  showArrows={false}
                  ref={carousel}
                  showEmptySlots
                >
                  {propletItems.length > 0 &&
                    propletItems.map((item, index) => {
                      return (
                        <PropertyBox
                          data={item}
                          key={""}
                          setIsOpen={setIsOpen}
                          isOpen={isOpen}
                        />
                      )
                    })}
                </Carousel>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default PropertyRelated
