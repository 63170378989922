/**
 * External dependencies
 */
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "gatsby"

/**
 * Internal dependencies
 */
import BtnLink from "../btn-link/btn-link"
import IconCircle from "../icon-circle/icon-circle"
import usePropertyDetails from "./use-property-details"
import VideoPlaceholder from "../video-placeholder/video-placeholder"
import parse from 'html-react-parser';
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import { AltSiteName } from "../utils";
import DateFormat from "../format-date";
import "./styles/_index.scss"
import Share from "../share/share"
import Breadcrumbs from "../breadcrumbs"
import {PropertValuationURL} from "../utils"
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

const PropertyDetails = (props) => {
    const items = usePropertyDetails()
    const videoImage = items[0].node.childrenImageSharp[0].fluid.src
    const [isOpen, setIsOpen] = useState(false)
    var item = props.GQLPage
    let processedImages = JSON.stringify({});
     if (item?.imagetransforms?.Banner_Image_Transforms) {
     processedImages = item.imagetransforms.Banner_Image_Transforms;
   }
   const location = useLocation();
  const thePath  = location.pathname

    return (
        <div className="property-details-wrapper">
         <Helmet>
  <script type="application/ld+json">{`{
  "@context": "http://schema.org",
"@type": "Article",
"mainEntityOfPage": {
"@type": "WebPage",
"@id": "https://www.lambornhill.com${thePath}"
},
"headline": "${item.Title}",
"image":{
"@type":"ImageObject",
"Url":"${item.Banner_Image.url}",
"Width":"888",
"Height":"540"
},
"datePublished": "${item.News_Published_Date}",
"dateModified": "${item.News_Published_Date}",
"author": {
"@type": "Person",
"name": "LambornHill"
},
"publisher": {
"@type": "Organization",
"name": "LambornHill",
"logo": {
"@type": "ImageObject",
"url": "https://www.lambornhill.com/static/logo-7830a5ce7a19b1450b31def831f04e5f.svg"
}
},
"description": "Read about ${item.Title} here and subscribe to stay up-to-date about everything going on at LambornHill."
 }`}</script>
 </Helmet>

            <div
                className="property-details"
                data-aos="fade-up"
                data-item="description"
            >
                <div className="shell">
                    <Breadcrumbs baselabel="News/Blogs" baseurl="/about/property-blogs/" menulabel={item.Title}/>
                    <Row className="justify-content-md-space-between">
                        <Col lg="9">
                            <h1>{item.Title}</h1>
                            <div className="news-meta">
                                <div className="news-date-meta">
                                <DateFormat date={item.News_Published_Date}/>
                                </div>
                                <span className="meta-sep">.</span>
                                <div className="news-cat-meta">
                                {item.Select_News_Category.Name}
                                </div>
                                <span className="meta-sep">.</span>
                                <div className="news-share-meta">
                                    <Share title={`Share this Article`} type='news'/>                                    
                                </div>
                            </div>
                        </Col>
                        <Col lg="7">
                            <div id="description" className="property-details__content">
                                <div className="news-banner-img">
                                <ImageTransform imagesources={item.Banner_Image && item.Banner_Image.url} renderer="srcSet" imagename="news-blogs.Banner_Image.commontile" attr={{ alt:item.Banner_Image.alternativeText?item.Banner_Image.alternativeText:item.Title+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>  
                                </div>
                                <div className="new-above-intro news-content">
                                {parse(item.Above_Content)}
                                </div>
                                {item.Video_Tile && item.Video_Tile.Video_Tile_Image  &&
                                <div className="news-video-tile">
                                <VideoPlaceholder
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                    image={item.Video_Tile.Video_Tile_Image && item.Video_Tile.Video_Tile_Image.url}
                                    videourl={item.Video_Tile.Video_URL}
                                />
                                </div>
                                }
                                {item.Below_Content &&
                                <div className="new-below-intro news-content">
                                {parse(item.Below_Content)}
                                </div>
                                }
                            </div>
                            
                        </Col>
                        <Col
                            lg={{
                                span: 4,
                                offset: 1,
                            }}
                        >
                            <div className="property-details__sticky">
                                <h4>Get in touch</h4>
                                <ul>
                                    <li>
                                        <Row className="align-items-md-center">
                                            <Col md="auto">
                                                <IconCircle color="#EAF2F4">
                                                    <i className="icon-pin"></i>
                                                </IconCircle>
                                            </Col>
                                            <Col>{'LambornHill, 19-21 West Street, Sittingbourne, Kent, ME10 1AJ'}</Col>
                                        </Row>
                                    </li>
                                    <li>
                                        <Row className="align-items-md-center">
                                            <Col md="auto">
                                                <IconCircle color="#EAF2F4">
                                                    <i className="icon-phone-bold"></i>
                                                </IconCircle>
                                            </Col>
                                            <Col>
                                                <a href={`tel: +${'01795 293000'}`}>{'01795 293000'}</a>
                                            </Col>
                                        </Row>
                                    </li>
                                    <li>
                                        <Row className="align-items-md-center">
                                            <Col md="auto">
                                                <IconCircle color="#EAF2F4">
                                                    <i className="icon-envelope"></i>
                                                </IconCircle>
                                            </Col>
                                            <Col>
                                                <Link to="/contact-estate-agents-in-sittingbourne/">
                                                    sittingbourne@lambornhill.com
                                                </Link>
                                            </Col>
                                        </Row>
                                    </li>
                                    <li>
                                        <Row className="align-items-md-center">
                                            <Col md="auto">
                                                <IconCircle color="#EAF2F4">
                                                    <i className="icon-price"></i>
                                                </IconCircle>
                                            </Col>
                                            <Col>
                                                Do you need to sell your home?{" "}
                                                <div><strong><Link to={PropertValuationURL}>Book a valuation</Link></strong> with one of our team.</div>
                                            </Col>
                                        </Row>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default PropertyDetails
