import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/Seo/seo"
import HomeIntro from "../components/home-intro/team-intro";
import StaticPage from '../components/property-details/static-content'
import PropertyRelated from '../components/property-related/featured-property'
import OtherStaffs from '../components/property-videos/other-staffs'
import NewsDetail from '../components/property-details/news-page'
import { Col, Row } from "react-bootstrap"
import HomeNews from "../components/home-news/home-news";
import HomeBook from "../components/home-book/home-book";

import {graphql } from 'gatsby'

const TeamDetails = (props) => {
 const GQLPage    = props.data.glstrapi?.newsBlog;
 var metadesc = "Read about "+GQLPage.Title+" here and subscribe to stay up-to-date about everything going on at LambornHill.";
 return (
     
  <Layout pageclass="details-news-page">

    <div className="news-details-wrapper">
    <SEO image={GQLPage.Banner_Image && GQLPage.Banner_Image.url} title={GQLPage.Meta_Title?GQLPage.Meta_Title:GQLPage.Title} description={GQLPage.Meta_Description?GQLPage.Meta_Description:metadesc} />
    <NewsDetail GQLPage={GQLPage}/>
    <HomeNews title="May also interest you..." class="othernewslist"/>
    <HomeBook title={GQLPage.Title}/>
    </div>
  </Layout>
 )
}
export default TeamDetails;


export const newsdetailQuery = graphql`
query NewsQuery($id: ID!) {
  glstrapi {
    newsBlog(id: $id) {  
        id
        Title
        Meta_Title
        Meta_Description
        URL  
        Select_News_Category {
          URL
          Name
        }
        Banner_Image {
          url
          alternativeText
        }
        News_Published_Date
        Above_Content
        Below_Content
        Video_Tile {
          Video_Tile_Image {
            url
            alternativeText
          }
          Video_URL
        }   
        imagetransforms 
    }
}
}`

