/**
 * External dependencies
 */
 import React, { useState } from "react"
 import { Col, Row } from "react-bootstrap"
 import { Link } from "gatsby"
 import HTMLReactParser from 'html-react-parser';
 
 /**
  * Internal dependencies
  */
 import BtnLink from "../btn-link/btn-link"
 import IconCircle from "../icon-circle/icon-circle"
 import usePropertyDetails from "./use-property-details"
 import VideoPlaceholder from "../video-placeholder/video-placeholder"
 import { isMobile } from 'react-device-detect';
 import ImageTransform from "../common/ggfx-client/module/components/image-transform";
 import { AltSiteName } from "../utils";
 import "./styles/_index.scss"
 
 const PropertyDetails = (props) => {
   const items = usePropertyDetails()
   const videoImage = items[0].node.childrenImageSharp[0].fluid.src
   const [isOpen, setIsOpen] = useState(false)
   let processedImages = JSON.stringify({});
   if (props?.imagetransforms?.Staff_Image_Transforms) {
   processedImages = props.imagetransforms.Staff_Image_Transforms;
   }
   return (

     <>         
         <div className="property-details-wrapper static-content">
          {props.image &&
          <div className="mobile-team-image introImage">
            <ImageTransform imagesources={props.image && props.image.url} renderer="srcSet" imagename="teams.Staff_Image.detailtile" attr={{ alt:props.image.alternativeText?props.image.alternativeText:props.title+AltSiteName}} imagetransformresult={processedImages} id={props.id}/>  
          </div>
          }
           <div
             className="property-details"
             data-aos="fade-up"
             data-item="description"
           >
             <div className="shell">
               <Row className="justify-content-md-space-between">
                 <Col lg="8">
                   <div id="description" className="property-details__content">
                     {
                       props?.content &&
                       <>
                         <p>{HTMLReactParser(props?.content)}</p>
                       </>
                     }
                   </div>
 
                   
                 </Col>
                 
               </Row>
             </div>
           </div>
         </div>
     </>
   )
 }
 
 export default PropertyDetails
 